@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #1c87c1 1.84%, #6dc3fc 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #15a1dc -13.86%, #1576a3 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #B8E7EA;
  --color-subtext: #FF8A71;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;   
}

.gradient__bg {
  
    /* safari 5.1+,chrome 10+ */
  
    /* opera 11.10+ */
  
    /* ie 10+ */
  
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  }

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes scale-up-center {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }
}
.header {
    display: flex;
}

.header-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 60px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: row;
}

.header-image {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .header {
        flex-direction: column;
    }

    .header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .header-content h1 {
        font-size: 45px;
        line-height: 60px;
    }

    .header-content p {
        font-size: 20px;
        line-height: 24px;
    }

}

@media screen and (max-width: 490px) {
    .header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .header-content p {
        font-size: 18px;
        line-height: 24px;
    }
}
.about {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.about-title {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.about p {
    font-family: var(--font-family);
    color: var(--color-text);
    margin-top: 35px;
    font-size: 15px;
}

.about-heading {
    margin: 2rem 0 0;
}

.about-heading h1 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

@media screen and (max-width: 850px) {
    
    .about-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 650px) {

    .about-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}
.features-container__feature {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
    margin: 1rem;
}

.features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .features-container__feature {
        margin: 1rem 0;
    }
}

.services {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.services-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}

.services-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.services-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    margin-top: 2rem;
}

.services-container {
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.service-icons img {
    width: 130px;
    height: 130px;
    margin-top: -35px;
    margin-right: 20px;
}

@media screen and (max-width: 990px) {

    .services {
        flex-direction: column;
    }

    .services-heading {
        margin: 0 0 2rem 0;
    }
}

@media screen and (max-width: 550px) {

    .services-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}

.team-container_biography {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: var(--color-footer);
}

.team-container_biography-image {
    width: 50%;
    height: 65%;
}

.team-container_biography-image img {
    width: 100%;
    height: 100%;
}

.team-container_biography-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
}

.team-container_biography-content p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    color: var(--color-text);
}

.team-container_biography-content h3 {
    font-family: var(--font-family);
    font-size: 28px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 0.5rem;
}

.team-container_biography-content h4 {
    margin-bottom: 1rem;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}

@media screen and (max-width: 550px) {

    .team-container_biography-content h3 {
        font-size: 24px;
        line-height: 25px;
    }

    .team-container_biography-content h4 {
        font-size: 19px;
        line-height: 25px;
    }
}

.team {
    display: flex;
    flex-direction: column;    
}

.team-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 4rem;
}

.team-heading h1 {
    text-align: center;
    font-size: 55px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.team-container {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 990px) {

    .team-container {
        flex-direction: column-reverse;
    }

    .team-container_biography  {
       width: 48%;
    }
}

@media screen and (max-width: 700px) {

    .team-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .team-container_biography  {
       width: 100%;
    }

    .team-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {

    .team-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}
.contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.contact-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #000000;
}

.contact-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.contact-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.contact-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.contact-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {

    .contact {
        flex-direction: column;
    }

    .contact-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {

    .contact {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .contact-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .contact-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.footer-copyright p {
    font-size: 13px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links_logo {
    margin-right: 2rem;
}

.navbar-links_logo img {
    width: 150px;
    height: 65px;
}

.navbar-links_container {
    display: flex;
    flex-direction: row;
}

.navbar-links_container p,
.navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-menu svg {
    cursor: pointer;
}

.navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.navbar-menu_container p {
    margin: 1rem 0;
}

.navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    
    .navbar-links_container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {

    .navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {

    .navbar {
        padding: 2rem;
    }

    .navbar-menu_container {
        top: 20px;
    }

    .navbar-menu_container-links-sign {
        display: block;
    }
}

.brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.brand div {
    flex: 1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aws-logo {
    font-size: 60px;
}

.cloud-logo {
    font-size: 50px;
}

.azure-logo {
    font-size: 40px;
}

@media screen and (max-width: 700px) {

    .aws-logo {
        font-size: 55px;
    }

    .cloud-logo {
        font-size: 45px;
    }

    .azure-logo {
        font-size: 35px;
    }
}

@media screen and (max-width: 550px) {

    .aws-logo {
        font-size: 50px;
    }

    .cloud-logo {
        font-size: 40px;
    }

    .azure-logo {
        font-size: 30px;
    }
}

@media screen and (max-width: 453px) {

    .aws-logo {
        font-size: 45px;
    }

    .cloud-logo {
        font-size: 35px;
    }

    .azure-logo {
        font-size: 25px;
    }
}

