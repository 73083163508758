.brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.brand div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aws-logo {
    font-size: 60px;
}

.cloud-logo {
    font-size: 50px;
}

.azure-logo {
    font-size: 40px;
}

@media screen and (max-width: 700px) {

    .aws-logo {
        font-size: 55px;
    }

    .cloud-logo {
        font-size: 45px;
    }

    .azure-logo {
        font-size: 35px;
    }
}

@media screen and (max-width: 550px) {

    .aws-logo {
        font-size: 50px;
    }

    .cloud-logo {
        font-size: 40px;
    }

    .azure-logo {
        font-size: 30px;
    }
}

@media screen and (max-width: 453px) {

    .aws-logo {
        font-size: 45px;
    }

    .cloud-logo {
        font-size: 35px;
    }

    .azure-logo {
        font-size: 25px;
    }
}
