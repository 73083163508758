.about {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.about-title {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.about p {
    font-family: var(--font-family);
    color: var(--color-text);
    margin-top: 35px;
    font-size: 15px;
}

.about-heading {
    margin: 2rem 0 0;
}

.about-heading h1 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

@media screen and (max-width: 850px) {
    
    .about-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 650px) {

    .about-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}