.header {
    display: flex;
}

.header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 60px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: row;
}

.header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .header {
        flex-direction: column;
    }

    .header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .header-content h1 {
        font-size: 45px;
        line-height: 60px;
    }

    .header-content p {
        font-size: 20px;
        line-height: 24px;
    }

}

@media screen and (max-width: 490px) {
    .header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .header-content p {
        font-size: 18px;
        line-height: 24px;
    }
}