.team-container_biography {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: var(--color-footer);
}

.team-container_biography-image {
    width: 50%;
    height: 65%;
}

.team-container_biography-image img {
    width: 100%;
    height: 100%;
}

.team-container_biography-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
}

.team-container_biography-content p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    color: var(--color-text);
}

.team-container_biography-content h3 {
    font-family: var(--font-family);
    font-size: 28px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 0.5rem;
}

.team-container_biography-content h4 {
    margin-bottom: 1rem;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}

@media screen and (max-width: 550px) {

    .team-container_biography-content h3 {
        font-size: 24px;
        line-height: 25px;
    }

    .team-container_biography-content h4 {
        font-size: 19px;
        line-height: 25px;
    }
}
